/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/

/* Similar Products Section */
.similar-products {
    margin-top: 60px;
    padding-top: 40px;
    border-top: 1px solid #eee;
}

.similar-products h4 {
    margin-bottom: 30px;
    font-size: 24px;
    text-align: center;
}

.similar-products-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin: 0;
}

.similar-products-grid .product {
    margin: 0;
    transition: transform 0.3s ease;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
}

.similar-products-grid .product:hover {
    border-color: var(--cnvs-themecolor);
}

.similar-products-grid .product-image {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%; /* Make it square */
    background: #fff;
}

.similar-products-grid .product-image a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.similar-products-grid .product-image img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    object-fit: contain;
    transition: transform 0.4s ease;
}

.similar-products-grid .product:hover .product-image img {
    transform: scale(1.05);
}

.similar-products-grid .product-desc {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #eee;
}

.similar-products-grid .product-title {
    margin-bottom: 10px;
}

.similar-products-grid .product-title h3 {
    font-size: 18px;
    margin: 0;
}

.similar-products-grid .product-title a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
}

.similar-products-grid .product-title a:hover {
    color: var(--cnvs-themecolor);
}

.similar-products-grid .product-price {
    font-size: 16px;
    color: var(--cnvs-themecolor);
    font-weight: 600;
}

/* Remove overlay styles */
.similar-products-grid .bg-overlay,
.similar-products-grid .bg-overlay-content,
.similar-products-grid .bg-overlay-bg {
    display: none;
}

@media (max-width: 991px) {
    .similar-products-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}

@media (max-width: 575px) {
    .similar-products-grid {
        grid-template-columns: 1fr;
        gap: 25px;
    }
    
    .similar-products {
        margin-top: 40px;
        padding-top: 30px;
    }
    
    .similar-products h4 {
        margin-bottom: 25px;
        font-size: 22px;
    }
}

/* Tab Content Styling */
.tab-content-inner {
    padding-top: 25px;
}

/* FAQ Styling */
#faqAccordion {
    margin-top: 15px;
}

#faqAccordion .accordion-item {
    border: 1px solid #eee;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
}

#faqAccordion .accordion-header {
    margin: 0;
}

#faqAccordion .accordion-button {
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    color: #333;
    transition: all 0.3s ease;
}

#faqAccordion .accordion-button:not(.collapsed) {
    background-color: #f8f9fa;
    color: var(--cnvs-themecolor);
    box-shadow: none;
}

#faqAccordion .accordion-button:focus {
    box-shadow: none;
    border-color: var(--cnvs-themecolor);
}

#faqAccordion .accordion-button::after {
    transition: transform 0.3s ease;
}

#faqAccordion .accordion-body {
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #eee;
    line-height: 1.6;
}

