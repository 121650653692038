.similar-products {
  border-top: 1px solid #eee;
  margin-top: 60px;
  padding-top: 40px;
}

.similar-products h4 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.similar-products-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 0;
  display: grid;
}

.similar-products-grid .product {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 0;
  transition: transform .3s;
  overflow: hidden;
}

.similar-products-grid .product:hover {
  border-color: var(--cnvs-themecolor);
}

.similar-products-grid .product-image {
  background: #fff;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.similar-products-grid .product-image a {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.similar-products-grid .product-image img {
  object-fit: contain;
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 90%;
  transition: transform .4s;
}

.similar-products-grid .product:hover .product-image img {
  transform: scale(1.05);
}

.similar-products-grid .product-desc {
  text-align: center;
  border-top: 1px solid #eee;
  padding: 20px;
}

.similar-products-grid .product-title {
  margin-bottom: 10px;
}

.similar-products-grid .product-title h3 {
  margin: 0;
  font-size: 18px;
}

.similar-products-grid .product-title a {
  color: #333;
  text-decoration: none;
  transition: color .3s;
}

.similar-products-grid .product-title a:hover {
  color: var(--cnvs-themecolor);
}

.similar-products-grid .product-price {
  color: var(--cnvs-themecolor);
  font-size: 16px;
  font-weight: 600;
}

.similar-products-grid .bg-overlay, .similar-products-grid .bg-overlay-content, .similar-products-grid .bg-overlay-bg {
  display: none;
}

@media (width <= 991px) {
  .similar-products-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media (width <= 575px) {
  .similar-products-grid {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .similar-products {
    margin-top: 40px;
    padding-top: 30px;
  }

  .similar-products h4 {
    margin-bottom: 25px;
    font-size: 22px;
  }
}

.tab-content-inner {
  padding-top: 25px;
}

#faqAccordion {
  margin-top: 15px;
}

#faqAccordion .accordion-item {
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}

#faqAccordion .accordion-header {
  margin: 0;
}

#faqAccordion .accordion-button {
  color: #333;
  background-color: #fff;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  transition: all .3s;
}

#faqAccordion .accordion-button:not(.collapsed) {
  color: var(--cnvs-themecolor);
  box-shadow: none;
  background-color: #f8f9fa;
}

#faqAccordion .accordion-button:focus {
  box-shadow: none;
  border-color: var(--cnvs-themecolor);
}

#faqAccordion .accordion-button:after {
  transition: transform .3s;
}

#faqAccordion .accordion-body {
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 20px;
  line-height: 1.6;
}
/*# sourceMappingURL=index.a0c4a16e.css.map */
